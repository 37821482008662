import Layout from "components/Layout";
import Article from "components/Article";
import { Link } from "gatsby";
import ArrowRight from "images/homepage/ArrowRight";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IOffer, fetchOffers } from "utils/offer-service";
import FAQCard from "components/homepageSections/FAQCard";
import EmailDialog from "components/dialogs/EmailDialog";
import Redirect from "components/Redirect";

const Container = styled.section.attrs({
    className: "container text-center",
})`
    margin-top: 20px;
    padding-top: 20px;
    margin-bottom: 20px;
`;

const DiscountHeader = styled.div`
    background: linear-gradient(
        180deg,
        #1db954 0%,
        rgba(54, 236, 71, 0.87) 464.41%
    );
    font-family: "Gilroy";
    border-radius: 24px 24px 0px 0px;
    padding: 12px;
    height: 72px;
    font-weight: 700;
    font-size: 32px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CardWrapper = styled.div`
    padding: 2px;
    border-radius: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const Card = styled.div`
    border-radius: 0px 0px 24px 24px;
    background: #fff;
    min-width: 220px;
    padding: 28px 20px 20px 20px;
    font-family: Gilroy;
    display: flex;
    flex-direction: column;
    flex: 1;
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.16);

    @media (min-width: 768px) {
        padding: 28px 20px 20px 20px;
    }
`;

const PriceContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 120px;
`;

const Storage = styled.div`
    color: #1d1d1f;
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
`;

const OldPlanValue = styled.div`
    position: relative;
`;

const OldPlanPrice = styled.div`
    position: relative;
    color: #000000;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    margin-top: 12px;
`;

const Special = styled.div`
    position: relative;
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    margin-top: 12px;
`;

const PlanValue = styled.div`
    color: #1cb954;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 12px;
`;

const PlanValueMinor = styled.span`
    color: #1cb954;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 21px;
`;

const PlanPeriod = styled.span`
    color: #424242;
    font-family: "Nunito";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
`;

const PlanCTA = styled.button`
    font-family: "Gilroy";
    font-weight: 600;
    border: none;
    color: #fff;
    border-radius: 100px;
    background: #000000;
    padding: 10px 26px;
    margin-top: 24px;
    width: 200px;
    display: inline-flex;
    justify-content: space-between;
    transition:
        width 0.1s linear,
        background-color 0.1s linear;
    text-decoration: none;

    &:focus {
        outline: 4px solid rgba(0, 0, 0, 0.2);
    }

    &:hover {
        background-color: #1db954;
        width: 180px;
    }

    @media (min-width: 768px) {
        width: 160px;
    }
`;

const Disclaimer = styled.div`
    color: grey;
    font-size: 16px;
    text-align: center;
`;

const Strike = styled.div`
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(7.782deg);
    width: 84px;
    height: 7px;
    background: rgba(28, 185, 84, 0.8);
    flex-shrink: 0;
    z-index: 2;
`;

const SoldOut = styled.div`
    margin-top: 12px;
`;

// Mobile container for vertical stacking
const MobileContainer = styled.div`
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0 16px;
    }
`;

const splitPrice = (price: string) => price.split(".");

const getPlanCard = (plan: IOffer) => {
    const [major, minor] = splitPrice(plan.price);
    const isSpecial = plan.periodInYears === "5";

    return (
        <CardWrapper>
            <DiscountHeader>
                {plan.periodInYears === "3"
                    ? "30% OFF"
                    : plan.periodInYears === "10"
                      ? "65% OFF"
                      : "Exclusive"}
            </DiscountHeader>
            <Card>
                <Storage>{plan.size}GB</Storage>
                <PlanPeriod>for {plan.periodInYears + " years"}</PlanPeriod>
                <PriceContainer>
                    {isSpecial ? (
                        <>
                            <Special>Black Friday Exclusive</Special>
                        </>
                    ) : (
                        <OldPlanValue>
                            <OldPlanPrice>{plan.oldPrice}</OldPlanPrice>
                            <Strike />
                        </OldPlanValue>
                    )}
                    <PlanValue>
                        {major}
                        {minor && <PlanValueMinor>.{minor}</PlanValueMinor>}
                    </PlanValue>
                </PriceContainer>
                <SoldOut>
                    <EmailDialog paymentLink={plan.paymentLink}>
                        <PlanCTA>
                            Claim <ArrowRight />
                        </PlanCTA>
                    </EmailDialog>
                </SoldOut>
            </Card>
        </CardWrapper>
    );
};

const Pricing: React.FC = () => {
    const [plans, setPlans] = useState([] as IOffer[]);

    useEffect(() => {
        fetchOffers().then((offers) => {
            setPlans(offers);
        });
    }, []);

    return (
        <Container id="pricing">
            <div className="d-md-none">
                <MobileContainer>
                    {plans.map((plan) => getPlanCard(plan))}
                </MobileContainer>
            </div>
            <div className="row d-none d-md-flex">
                {plans.map((plan) => (
                    <div
                        className="col-md-6 col-lg-4 mb-3 d-flex"
                        key={plan.id}
                    >
                        {getPlanCard(plan)}
                    </div>
                ))}
            </div>
        </Container>
    );
};

const blackFridayEnabled = false;

export default function BlackFriday() {
    if (!blackFridayEnabled) {
        return <Redirect to={"/"} />;
    }
    return (
        <Layout title="Ente's last Black Friday sale" shouldShowBanner={false}>
            <Article>
                <Article.Header>
                    <div style={{ marginBottom: "-2rem" }}>
                        <Article.Heading className="text-center">
                            Ente's last Black Friday sale
                        </Article.Heading>
                        <div className="vstack text-center text-muted gap-md-1 pt-2">
                            <div>Welcome to our last Black Friday sale!</div>
                        </div>
                    </div>
                </Article.Header>

                <Article.BodyS>
                    <Pricing />
                    <Disclaimer>
                        Discount calculated against monthly plans.
                    </Disclaimer>
                    <hr />
                    <FAQs />
                </Article.BodyS>
            </Article>
        </Layout>
    );
}

const FAQsArr = [
    {
        question: <>Why is this Ente's last Black Friday sale?</>,
        answer: (
            <>
                <a href="/blog/pricing-update/">Our prices</a> are as low as
                possible and cannot be discounted further. But we are running
                one last Black Friday sale where you can purchase long term
                plans at a discount, to thank early supporters.
            </>
        ),
    },
    {
        question: <>Are the deals available to existing customers?</>,
        answer: (
            <>
                Yes, these deals are available to everyone. We're grateful to
                our existing customers who helped us get here.
                <br />
                <br />
                The storage you buy will be added to your existing subscription.
                For example, if you're subscribed to our 200GB plan and get the
                500GB deal, your total storage will be 700GB for 5 years. If you
                later cancel your subscription, you'll have access to 500GB.
            </>
        ),
    },
    {
        question: <>Can I share with family?</>,
        answer: <>Yes!</>,
    },
    {
        question: <>Can I buy multiple deals?</>,
        answer: <>No, you can only buy one deal.</>,
    },
    {
        question: <>Can I pay with Crypto?</>,
        answer: (
            <>
                Yes, please drop a mail to{" "}
                <a href="mailto:crypto@ente.io">crypto@ente.io</a> citing the
                deal of your choice. We'll share an invoice where you can pay
                with a crypto currency of your choice.
            </>
        ),
    },
    {
        question: <>Can I get a refund?</>,
        answer: (
            <>
                You can request for a refund within 14 days of making a purchase
                by writing to{" "}
                <a href="mailto:support@ente.io">support@ente.io</a>.
            </>
        ),
    },
    {
        question: <>What happens when my deal expires?</>,
        answer: (
            <>
                When your deal expires, you can subscribe to one of{" "}
                <a href="/#pricing" target="_blank">
                    our plans
                </a>{" "}
                to keep your account active.
            </>
        ),
    },
    {
        question: <>For how long is the Black Friday deal live?</>,
        answer: <>The deal is live till midnight 2nd December, 2024.</>,
    },
    {
        question: <>More questions?</>,
        answer: (
            <>
                Please join us on{" "}
                <a href="https://ente.io/discord" target="_blank">
                    Discord
                </a>{" "}
                .
            </>
        ),
    },
];

export function FAQs() {
    return (
        <Container>
            <h2>FAQ</h2>
            {FAQsArr.map((faq, index) => (
                <FAQCard
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                    expanded={true}
                />
            ))}
        </Container>
    );
}
